import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/tmp/vultr-2-runner/work/kastle/kastle/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "les-loisirs-du-château"
    }}>{`Les loisirs du Château`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4712e030725ca58ca34943dab647655d/eed55/LesLoisirsPiscine2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.104294478527606%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB80lEQVQY0wHoARf+ACY/Eyc7ES5BFSc3EiQyETREFjtQGyI1FCU4FCc7FB8xHDBHGzBIEiE4DjlRDE1oK0RZMjVFISxNWjFgbgAoPxMuRRU3VBg9WBk4TBcuQBArPhUZKREqQBY0SxYtRRQySg06ThcjNw8fLQdYbSRUZCk2RQ0zVE1DgJMAHCcPHSYQIi8VHS0NKjoTLjocLzgsJjImGCQJEB0EGigNHzAKHigMMDoQITASPlAaWGkyQE0eO1AkSVtaAA8RDg0XBAwdAjA5Km16a5aopKi/xafDynyRl0lcTREbDBEWCxYcDis2Dy88HDxZQVdrPktbNExhRGySnQAWJg0iNh9JWk6ImIpQWko2LCFpW1Odnpa1xMKuw8V3goMfIx0YJREwQC1ESixbZFJRZTNLXitOZVUsOT8Ac4hQk6eSq7utsb+xmbGjbIpvY2lVdHtllKKSmaSdq7Wuhop4ZmU3X1w/YGI/UlQnPU8OPVUaJC4VBAAAAHOFPbenjcm0ndG8psnGtKrU0XvZ5VTR4ljR3TjO6DTK4lDO41XM3lqfoYOXhIaGYFRaI4iFYCsoICEiGABddSKemm3UvKzTvKjewarjwqvnzbfc0sHP2MvE2My82Mu21sqo083Az8S2sJ6Wh2lWXixRVUYwLyMyNiY73qgr4ePXgAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4712e030725ca58ca34943dab647655d/f994e/LesLoisirsPiscine2.webp 163w", "/static/4712e030725ca58ca34943dab647655d/e93cc/LesLoisirsPiscine2.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4712e030725ca58ca34943dab647655d/cf3f8/LesLoisirsPiscine2.png 163w", "/static/4712e030725ca58ca34943dab647655d/eed55/LesLoisirsPiscine2.png 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4712e030725ca58ca34943dab647655d/eed55/LesLoisirsPiscine2.png",
              "alt": "Loisir au Château de Morey",
              "title": "Loisir au Château de Morey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Loisir au Château de Morey`}</strong></p>
    <h2 {...{
      "id": "besoin-de-relaxation-et-de-détente-au-coeur-de-la-lorraine-"
    }}>{`Besoin de relaxation et de détente au coeur de la Lorraine ?`}</h2>
    <h3 {...{
      "id": "moment-de-bien-être-entre-nancy-et-metz"
    }}>{`Moment de bien être entre Nancy et Metz`}</h3>
    <p>{`Venez trouver le confort au Château de Morey.
Profitez de notre `}<em parentName="p">{`SPA privatif`}</em>{` avec 25 jets de massage réglables dans une eau à 38 degrés aux huiles essentielle de `}<strong parentName="p">{`Lorraines`}</strong>{`.
Relaxez-vous dans une ambiance zen dans ce petit cocoon chaleureux et insolite.`}</p>
    <h3 {...{
      "id": "pour-un-massage-détente-en-chambre-"
    }}>{`Pour un massage détente en chambre :`}</h3>
    <p>{`Contacter Céline au `}<a parentName="p" {...{
        "href": "tel:+33661347300",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`06 61 34 73 00`}</a></p>
    <h2 {...{
      "id": "les-activités-du-château-de-morey"
    }}>{`Les activités du Château de Morey`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/26edbe93598e303a68bea7e6a402e2c0/eed55/LesLoisirsPiscine.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.104294478527606%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB80lEQVQY0wHoARf+ABgmECA0FS5GFic8EyxGEChEDSA4DRYnCDNPEkZgGkdeMU+Vt0if10Wk2UCr41twjW88OnA6IYErFHoxJwAYIw8UHwQLFwESHggfNA0XJwcUIAgeLxQZKws4UgtKWg1XiHpXruFsjrGdXXaga41iia5DcHRFXz4zV1QAjaiwcIiNUWRYHCYUBwwAFRwKFiAJHi8QL00/O11AOk4bXYJrd7rgjXeFnVlgqmVukYaVWn+CQk4fKDMSAKi+w5/AyZzCyoOorTtJSgQJBB4uHSk0IHqZpXOaqj5VQIKmsJO6y4/B1n6yyIK7zIq8z2yHjTpEIiw5GwB9Y06hnY6jnZWtraWwr6dYWEIwNRxeUz6BcVRRWUMvTBU+XTsoOh9BWEBMXz1CVS46Wz81SD4hJwsiLQsAVJCIXaajZaaqWqmxYa+2c7a5aKSbeqichqqTlKqMsK6EkIxqeXded3NWhodegoJdVl5AGBwRHSIKKzURAH7W5GLS4mDZ40Tc80Ha71XY6k7Y7GHW6IvY48be2fPVwqCJhXRjaGtaYWhkbWFZWkA2NhsbFSYrEDA3GgDlwavkx7Hhy7fjzLfky7Tmya/qyavtyrDwy7DtxqzkxKzTtZ/GqpaZg3VQSkUrMCYzNyojIhEyORc2Ox5vP8V+cif5BgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26edbe93598e303a68bea7e6a402e2c0/f994e/LesLoisirsPiscine.webp 163w", "/static/26edbe93598e303a68bea7e6a402e2c0/e93cc/LesLoisirsPiscine.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26edbe93598e303a68bea7e6a402e2c0/cf3f8/LesLoisirsPiscine.png 163w", "/static/26edbe93598e303a68bea7e6a402e2c0/eed55/LesLoisirsPiscine.png 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/26edbe93598e303a68bea7e6a402e2c0/eed55/LesLoisirsPiscine.png",
              "alt": "Loisir en Lorraine",
              "title": "Loisir en Lorraine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Loisir en Lorraine`}</strong></p>
    <h3 {...{
      "id": "un-complexe-de-détente"
    }}>{`Un complexe de détente`}</h3>
    <p>{`Vous pourrez profiter d’un maximum d’espace bien être.
Notre piscine creusée construite en 2001, permet de bronzer, toute en étant entouré d’un parc arboré avec le bruit de ses oiseaux. Un moment de relaxation assuré.
Près de `}<em parentName="p">{`Nancy et Metz`}</em>{`, rafraîchissez vous lors des saisons très chaudes de la `}<em parentName="p">{`Lorraine`}</em>{`.`}</p>
    <h3 {...{
      "id": "appréciez-de-multiples-activités-au-sein-de-notre-demeure-"
    }}>{`Appréciez de multiples activités au sein de notre demeure :`}</h3>
    <ul>
      <li parentName="ul">{`💦 Spa`}</li>
      <li parentName="ul">{`💆‍ Massage`}</li>
      <li parentName="ul">{`🏊‍ Piscine`}</li>
      <li parentName="ul">{`🐴 Balade à cheval`}</li>
      <li parentName="ul">{`🏐 Boulodrome`}</li>
      <li parentName="ul">{`🎱 Billiard`}</li>
      <li parentName="ul">{`🎲 Salle de jeux`}</li>
      <li parentName="ul">{`🌳 Un parc arboré d’exception`}</li>
      <li parentName="ul">{`🚴‍ Des vélos à votre disposition`}</li>
      <li parentName="ul">{`🌐 Wifi gratuit`}</li>
      <li parentName="ul">{`📚 Bibliothèque`}</li>
      <li parentName="ul">{`🐾 Des animaux`}</li>
    </ul>
    <p>{`Notre parc bucolique composé d’un vaste jardin soutenu par une muraille de plus de 5 mètres de hauteur.
Avec des Marronniers, cèdre du Liban, tilleuls, hêtres pourpres, chênes, érable et une marre aux Canards.
Un endroit parfait pour se promener et se détendre.`}</p>
    <h2 {...{
      "id": "visitez-la-lorraine"
    }}>{`Visitez la `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d85ff176205ab28d0b0779e0558eabf6/af98b/placestan.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAHm2zhFoULP/8QAGRABAQEAAwAAAAAAAAAAAAAAAAERAhIT/9oACAEBAAEFAvOpwdY1a1//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIT/9oACAEDAQE/AXCM5P/EABcRAAMBAAAAAAAAAAAAAAAAAAABAhP/2gAIAQIBAT8BVM0o/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRABAQADAQAAAAAAAAAAAAAAAQARMVGh/9oACAEBAAE/IeGGQ3CG/IRKwjl//9oADAMBAAIAAwAAABBXL//EABYRAQEBAAAAAAAAAAAAAAAAAAABcf/aAAgBAwEBPxCls//EABYRAQEBAAAAAAAAAAAAAAAAAAABcf/aAAgBAgEBPxCNg//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExcUFR/9oACAEBAAE/ECkUD6MQcQNrMYvxMqariXNk1XzEErLpP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d85ff176205ab28d0b0779e0558eabf6/f994e/placestan.webp 163w", "/static/d85ff176205ab28d0b0779e0558eabf6/bcd70/placestan.webp 325w", "/static/d85ff176205ab28d0b0779e0558eabf6/5d8d7/placestan.webp 650w", "/static/d85ff176205ab28d0b0779e0558eabf6/c4443/placestan.webp 975w", "/static/d85ff176205ab28d0b0779e0558eabf6/5cc32/placestan.webp 1300w", "/static/d85ff176205ab28d0b0779e0558eabf6/448ad/placestan.webp 1613w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d85ff176205ab28d0b0779e0558eabf6/367e5/placestan.jpg 163w", "/static/d85ff176205ab28d0b0779e0558eabf6/ab07c/placestan.jpg 325w", "/static/d85ff176205ab28d0b0779e0558eabf6/ebabe/placestan.jpg 650w", "/static/d85ff176205ab28d0b0779e0558eabf6/cdb69/placestan.jpg 975w", "/static/d85ff176205ab28d0b0779e0558eabf6/2616f/placestan.jpg 1300w", "/static/d85ff176205ab28d0b0779e0558eabf6/af98b/placestan.jpg 1613w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d85ff176205ab28d0b0779e0558eabf6/ebabe/placestan.jpg",
              "alt": "place stanislas",
              "title": "place stanislas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Place stanislas de Nancy`}</strong></p>
    <h3 {...{
      "id": "situé-entre-nancy-et-metz-la-découverte-de-ces-charmantes-villes-pourrait-bien-vous-épatez"
    }}>{`Situé entre `}<strong parentName="h3">{`Nancy et Metz`}</strong>{`, la découverte de ces charmantes villes pourrait bien vous épatez.`}</h3>
    <p><strong parentName="p">{`Nancy`}</strong>{`, à 20 minutes du
`}<strong parentName="p">{`Château de Morey`}</strong>{`, est connu pour sa magnifique `}<strong parentName="p">{`Place Stanislas`}</strong>{` et pour sa vieille ville qui vous plongera dans le `}<strong parentName="p">{`Moyen-Age de la Lorraine`}</strong>{`. Beaucoup de musées se retrouvent dans cette ville qui regorge d’histoire.`}</p>
    <p><strong parentName="p">{`Metz`}</strong>{`, à 30 minutes du `}<strong parentName="p">{`Château de Morey`}</strong>{`, tient sa renommée à sa somptueuse cathédrale et l’architecture impériale germanique de la ville.
Laissez vous guider dans les charmes de cette ville qui retranscrit de multiples époques.`}</p>
    <h3 {...{
      "id": "la-lorraine-héberge-de-nombreux-sites-et-monuments-à-voir-de-toutes-les-époques-cest-un-privilège-davoir-une-région-si-riche-en-histoire"
    }}>{`La `}<strong parentName="h3">{`Lorraine`}</strong>{` héberge de nombreux sites et monuments à voir, de toutes les époques, c’est un privilège d’avoir une région si riche en histoire.`}</h3>
    <p>{`À 15 minutes du `}<strong parentName="p">{`Château de Morey`}</strong>{`, `}<strong parentName="p">{`Pont à Mousson`}</strong>{` est une petite ville pleine de charme, elle regroupe beaucoup de restaurants locaux, mais également certains monuments exceptionnels.
Le plus connu est l’`}<strong parentName="p">{`Abbaye des prémontrés`}</strong>{`, monument du 18e siècle, c’est un magnifique exemple de l’architecture monastique de `}<strong parentName="p">{`Lorraine`}</strong>{`.`}</p>
    <p>{`Quelques kilomètres plus loin de chez nous, vous retrouverez :`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Verdun`}</strong>{` et ses sites historiques de la Seconde Guerre mondiale`}</li>
      <li parentName="ul">{`Le `}<strong parentName="li">{`Lac de la Madine`}</strong>{` et le monument de la Butte de Montsec`}</li>
    </ul>
    <p>{`Pour les passionnés de `}<strong parentName="p">{`Château`}</strong>{`, nous avons des confrères dans la région :`}</p>
    <ul>
      <li parentName="ul">{`Le Château de `}<a parentName="li" {...{
          "href": "https://www.chateau-malbrouck.com/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Malbrouck`}</a></li>
      <li parentName="ul">{`Le Château de `}<a parentName="li" {...{
          "href": "https://fr.wikipedia.org/wiki/Ch%C3%A2teau_de_Lun%C3%A9ville",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Luneville`}</a></li>
      <li parentName="ul">{`Le Château de `}<a parentName="li" {...{
          "href": "https://www.tourisme-lorraine.fr/a-voir-a-faire/visites/sites-et-monuments/995000870-chateau-de-haroue-haroue",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Haroue`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      